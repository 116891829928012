<template>
    <section class="form-container">
        <section class="form-item m-t-18">
            <a-form :form="form" layout="inline">
                <a-row>
                    <a-form-item label="文档标题">
                        <a-input style="width:300px" placeholder="请输入" v-decorator="['topic',{rules:[{required:true,message:'请输入文档标题'}]}]"></a-input>
                    </a-form-item>
                </a-row>
                <a-row>
                    <a-form-item label="文档类型">
                        <a-radio-group @change="e => changeType(e.target.value)" v-decorator="['resourceType',{rules:[{required:true,message:'请输入文档类型'}]}]">
                          <a-radio :value="1">文本</a-radio>
                          <a-radio :value="2">图片</a-radio>
                          <a-radio :value="4">视频</a-radio>
                        </a-radio-group>
                    </a-form-item>
                </a-row>
                <a-row v-if="resourceType == 1">
                    <a-form-item label="文档内容">
                        <a-textarea rows="6" v-decorator="['contentText',{rules:[{required:true,message:'请输入文档内容'}]}]" placeholder="请输入" style="width:600px" ></a-textarea>
                    </a-form-item>
                </a-row>
                <a-row v-else-if="resourceType == 2">
                    <a-form-item label="文档图片">
                        <a-row type="flex">
                            <a-row class="upload-preview-wrap">
                                <a v-for="item in fileLists" :key="item.codeUrl" class="file-item m-r-10">
                                    <a-avatar  shape="square" :size="100" :src="item.url" />
                                    <a class="close-icon" title="删除" @click="deleteFile(item)"><a-icon type="close"></a-icon></a>
                                </a>

                            </a-row>
                            <upload style="display:inline-block;width:100px" v-decorator="['fileUrl',{rules:[{required:true,message:'请上传照片'}]}]" :node="{type:3,text:'图片'}" :accept="['.png','.jpg']" @func="uploadFile" />
                        </a-row>
                    </a-form-item>
                </a-row>
                <a-row v-else>
                    <a-form-item label="文档视频">
                        
                        <upload v-decorator="['fileUrl',{rules:[{required:true,message:'请上传照片'}]}]" :node="{type:2,text:'视频上传'}" :accept="['.mp4']" @func="uploadFile" />
                        <a-row v-if="fileLists.length">
                            <a-row style="color:#FFFFFF" v-for="(item,ind) in fileLists" :key="ind">{{item.fileName}}</a-row>
                        </a-row>
                    </a-form-item>
                </a-row>
                <a-row class="form-btn-group" style="padding-left:80px">
                   <a-button class="btn-save" @click="submit" :disabled="submitLoadding" :loading="submitLoadding"><a-icon v-if="!submitLoadding" type="save" />保存</a-button>
                   <a-button class="btn-cancel"  @click="$router.back()">取消</a-button>
               </a-row>
            </a-form>
        </section>
    </section>
</template>

<script>
import upload from '@/components/upload'
export default {
    data(){
        return{
            fileLists:[],
            resourceType:1
        }
    },
    components:{upload},
    beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    computed:{
        kid(){
            return this.$route.query.id
        },
        submitLoadding(){
            return this.$store.state.submitLoadding
        }
        
    },
    created(){
        this.rid && this.queryRoleDetails()
        this.$nextTick(() =>{
            this.form.setFieldsValue({
                resourceType:1
            })
        })
    },
    methods:{
        changeType(val){
            this.resourceType = val
        },
        uploadFile(file){
            if(this.resourceType == 2){
                this.fileLists.push(file) 
            }else{
                this.fileLists = [file]
            }
            
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    fileUrl:this.fileLists.length ? JSON.stringify(this.fileLists) : ''
                })
            })
        },
        deleteFile(list){
            this.fileLists = this.fileLists.filter(item => item.codeUrl != list.codeUrl)
        },
        submit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    this.$store.commit('SET_SUBMITLOADDING',true)
                    if(this.rid){
                        formData.id = this.rid
                    }
                   
                    this.$api.addPublicizeRes(formData).then(res =>{
                        if(res.code === 200){
                            this.$router.back()
                        }
                    })
                }
            })
        }
    }
}
</script>